import React, { FC, ReactElement, useCallback, useMemo, useState } from 'react';
import { UmbracoAccordionItem, UmbracoProductDetail } from '@shared/types';

import Accordion from 'components/elements/Accordion';
import DangerouslySetInnerHtml from 'components/helpers/DangerouslySetInnerHtml';

import { IPropsProductDetails } from './models';

import './ProductDetails.scss';

const ProductDetails: FC<IPropsProductDetails> = ({ data }): ReactElement | null => {
  const openedIndexFromCMS = data[0].items.findIndex((item) => item.isOpened === '1');
  const [accordionIndex, setAccordionIndex] = useState<number>(openedIndexFromCMS);

  const onChangeCallback = useCallback((selectedIndex: number) => {
    setAccordionIndex((prevState) => (selectedIndex === prevState ? -1 : selectedIndex));
  }, []);

  const items: UmbracoAccordionItem.IStructure[] = useMemo(
    () =>
      data[0].items.map((item: UmbracoProductDetail.IStructure) => ({
        ...item,
        content: item?.text ? (
          <DangerouslySetInnerHtml html={item.text} className="product-details__content" />
        ) : (
          ''
        ),
      })),
    [data]
  );

  return (
    <div data-testid="ProductDetails" className="product-details">
      <Accordion
        accordion={{ items }}
        scrollTabIntoView
        selectedIndex={accordionIndex}
        onChange={onChangeCallback}
        changeOnClick={false}
      />
    </div>
  );
};

export default ProductDetails;
