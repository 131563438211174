import React, { FC, useMemo } from 'react';
import { UmbracoProductCard } from '@shared/types';

import ProductCard from 'components/cards/ProductCard';
import Button from 'components/elements/Button';
import Carousel from 'components/elements/Carousel';
import Typography from 'components/elements/Typography';
import BaseSection from 'components/helpers/BaseSection';
import { sortByOrderUrls } from 'utils/sorting';

import { IPropsPopularProducts } from './models';

import './PopularProducts.scss';

const PopularProducts: FC<IPropsPopularProducts> = ({
  data: [data],
  globalData,
  popularProductsByLink,
  popularProductsByTag,
}) => {
  const sortProductsByIndex = useMemo(
    () => sortByOrderUrls(popularProductsByLink, data.selectedProductsLinks),
    [data, popularProductsByLink]
  );

  const itemsToRender =
    data?.selectedProductsMode === 'manual' && popularProductsByLink?.length
      ? sortProductsByIndex
      : popularProductsByTag;

  return (
    <BaseSection data={data.sectionSettings}>
      <div data-testid="PopularProducts" id="PopularProductsSection" className="popular-products">
        {data?.title?.length ? (
          <Typography data={data.title} customClass="popular-products__title" />
        ) : null}
        <Carousel carouselData={data.carouselSettings} globalData={globalData}>
          {itemsToRender?.map((product: UmbracoProductCard.IStructure, index) => (
            <ProductCard
              data={product}
              variant={data?.productCardVariant || 'default'}
              key={product.url}
              cartctabutton={data.cartctabutton}
              gridbuyMikMak={data.gridbuyMikMak}
              
              grideanvalue = {data?.grideanvalue?.length ? data?.grideanvalue[index] : null}
            />
          ))}
        </Carousel>
        {data?.ctaButton?.length ? (
          <div className="popular-products__cta">
            <Button data={data.ctaButton} />
          </div>
        ) : null}
      </div>
    </BaseSection>
  );
};

export default PopularProducts;
